import { Injectable, signal } from '@angular/core';
import { ActivityI } from './interfaces/activity.interface';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  selectedActivity = signal<ActivityI | null>(null);

  editActivity = signal<ActivityI | null>(null);

  deleteActivity = signal<ActivityI | null>(null);

  addActivity = signal<ActivityI | null>(null);
}
